import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined"
import { MultiLevelMenu } from "@react-admin/ra-navigation"
import { IfCanAccess } from "@react-admin/ra-rbac"
import { createElement, useCallback, useMemo } from "react"
import { useResourceDefinitions, useTranslate } from "react-admin"

export function Menu() {
  const resources = useResourceDefinitions()
  const translate = useTranslate()
  const menuItem = useCallback(
    (name: string) => (
      <IfCanAccess key={name} resource={name} action="list">
        <MultiLevelMenu.Item
          name={name}
          to={`/${name}`}
          label={translate(`resources.${name}.name`, { smart_count: 2 })}
          icon={resources[name].icon && createElement(resources[name].icon)}
        />
      </IfCanAccess>
    ),
    [resources, translate]
  )

  const reports = useMemo(
    () => Object.keys(resources).filter((name) => name.includes("_report")),
    [resources]
  )
  return (
    <MultiLevelMenu>
      {Object.keys(resources)
        .filter((name) => !name.includes("_report") && !name.includes("/"))
        .map(menuItem)}
      {reports.length > 0 && (
        <MultiLevelMenu.Item
          label="Project Reports"
          name="reports"
          icon={<SummarizeOutlinedIcon />}
        >
          {reports.map(menuItem)}
        </MultiLevelMenu.Item>
      )}
    </MultiLevelMenu>
  )
}
