import { AuthLayout, SetPasswordForm } from "ra-supabase-ui-materialui"
import { useEffect } from "react"
import { supabase } from "./Providers/supabase"

export const SetPassword = () => {
  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === "SIGNED_IN") {
        window.location.replace("/")
      }
    })
    return () => {
      subscription.unsubscribe()
    }
  }, [])

  return (
    <AuthLayout
      backgroundImage="https://unsplash.com/photos/80zZ1s24Nag/download?ixid=MnwxMjA3fDB8MXxhbGx8fHx8fHx8fHwxNjYyNTcxODAw&force=true&w=640"
      onSubmit={() => {
        window.location.replace("/")
      }}
    >
      <SetPasswordForm />
    </AuthLayout>
  )
}
