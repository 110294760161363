import { Datagrid, List } from "@react-admin/ra-rbac"
import { useEffect, useState } from "react"
import {
  AutocompleteArrayInput,
  DateField,
  DateInput,
  FileField,
  FunctionField,
  NumberField,
  NumberInput,
  Pagination,
  ReferenceArrayInput,
  ReferenceField,
  TextField,
  TextInput,
  useListContext,
} from "react-admin"
import { useOrganizationId } from "../../../hooks/useOrganizationId"
import { CrewMemberInput } from "../../Tool/CrewMemberInput"

const MaintenanceFilters = (organization_id) => [
  <TextInput
    key="tool(search_terms)"
    label="Search"
    // We are doing this rather than wfts so we can get partial matches on the final word
    source="tools.search_terms@fts"
    alwaysOn
  />,
  <ReferenceArrayInput
    reference="service_templates"
    source="tool_services.service_template_id@in"
    filter={{ organization_id, "deleted_at@is": null }}
  >
    <AutocompleteArrayInput
      source="name"
      optionText="name"
      filterToQuery={(query) => ({
        "name@ilike": query,
      })}
    />
  </ReferenceArrayInput>,
  <NumberInput source="cost@gte" />,
  <NumberInput source="cost@lte" />,
  <CrewMemberInput
    source="crew_member_id"
    organization_id={organization_id}
    showAutoToolroom={false}
  />,
  <DateInput source="completed_at@gte" />,
  <DateInput source="completed_at@lte" />,
]

export const ServiceRecordList = () => {
  const [organization_id] = useOrganizationId()
  const maintenanceFilters = MaintenanceFilters(organization_id)
  const [toolServices, setToolServices] = useState("tool_services")

  return (
    <List
      filters={maintenanceFilters}
      // exporter={maintenanceExporter}
      sort={{ field: "completed_at", order: "DESC" }}
      filter={{
        "select@": `*,tools!inner(search_terms,full_display_name,asset_tag),crew_members!inner(name), ${toolServices}(service_template_id, service_templates(name))`,
        "deleted_at@is": null,
        organization_id,
        "tools.organization_id": organization_id,
        "crew_members.organization_id": organization_id,
      }}
      perPage={25}
      pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
    >
      <ServiceRecordDatagrid onToolServices={setToolServices} />
    </List>
  )
}

function ServiceRecordDatagrid({ onToolServices, ...props }) {
  const { filterValues } = useListContext()
  const isInnerToolServices =
    filterValues?.tool_services?.["service_template_id@in"] !== undefined
  useEffect(() => {
    onToolServices(
      isInnerToolServices ? "tool_services!inner" : "tool_services"
    )
  }, [isInnerToolServices, onToolServices])
  return (
    <Datagrid rowClick="show" {...props}>
      <TextField source="tools.asset_tag" />
      <TextField source="tools.full_display_name" />
      <ReferenceField
        source="tool_services.service_template_id"
        reference="service_templates"
      />
      <TextField source="notes" />
      <TextField source="cost" />
      <FileField
        label="resources.service_records.fields.attachment"
        source="attachment.src"
        target="_blank"
        title="attachment.title"
      />
      <FunctionField
        source="checklist"
        render={(record, source) => {
          const checklist = record[source]
          return checklist
            ? checklist
                .map((item) => item.step + (item.complete ? " ✓" : " ✗"))
                ?.join(", ")
            : ""
        }}
      />
      <TextField
        source="crew_members.name"
        label="resources.service_records.fields.crew_member_id"
      />
      <DateField showTime showDate source="completed_at" />
      <NumberField source="competed_utilization" />
    </Datagrid>
  )
}
