import { Button, useCreatePath, useRedirect } from "react-admin"

export function HH2ImportButton({
  resource,
  jobId,
}: {
  resource: string
  jobId?: string
}) {
  const createPath = useCreatePath()
  const redirect = useRedirect()
  let path = createPath({ resource: `hh2/${resource}`, type: "list" })
  if (jobId) {
    path = `${path}?jobId=${jobId}`
  }
  return (
    <Button
      to={path}
      onClick={() => {
        redirect(path)
      }}
      label={`ra.action.hh2.${resource}.import.button`}
      startIcon={<img width="12" height="12" src="/sage.png" alt="Sage" />}
    />
  )
}
