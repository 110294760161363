import UploadIcon from "@mui/icons-material/Upload"
import {
  Button,
  Datagrid,
  List,
  SearchInput,
  TextField,
  useCreate,
  useListContext,
  useNotify,
  useUnselectAll,
} from "react-admin"
import { useOrganizationId } from "../../hooks/useOrganizationId"

const HH2CostCodeImportButton = () => {
  const { resource, data, selectedIds } = useListContext()
  const [organization_id] = useOrganizationId()
  const notify = useNotify()
  const unselectAll = useUnselectAll(resource)

  const selectedRecords = data.filter((record) =>
    selectedIds.includes(record.id)
  )
  const [create, { isLoading }] = useCreate("cost_codes")
  return (
    <Button
      label="ra.action.hh2.cost_codes.import.selected"
      disabled={isLoading}
      onClick={async () => {
        await Promise.all(
          selectedRecords.map((record) =>
            create(
              "cost_codes",
              {
                data: {
                  organization_id,
                  full_code: record.code,
                  cost_code_name: record.name,
                  procore_cost_code_id: record.id,
                },
              },
              {
                onError: (error: string) => {
                  console.error(error)
                },
              }
            )
          )
        )
        notify(`ra.action.hh2.cost_codes.import.notification`, {
          type: "success",
          messageArgs: { smart_count: selectedRecords.length },
        })
        unselectAll()
      }}
    >
      <UploadIcon />
    </Button>
  )
}
export function HH2CostCodeList() {
  const [organization_id] = useOrganizationId()
  // const url = new URL(window.location.href)
  // const jobId = url.searchParams.get("jobId")

  return (
    <List
      filter={{
        organization_id,
        // jobId
      }}
      filters={[<SearchInput source="q" alwaysOn />]}
    >
      <Datagrid bulkActionButtons={<HH2CostCodeImportButton />}>
        <TextField source="code" />
        <TextField source="name" />
      </Datagrid>
    </List>
  )
}
