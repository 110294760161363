import { AutocompleteInput, ReferenceInput, SimpleForm } from "react-admin"
import { useOrganizationId } from "../../../hooks/useOrganizationId"
import { DueUtilizationInput } from "../../Tool/CreateToolServiceButton"
import { CrewMemberInput } from "../../Tool/CrewMemberInput"

export const ToolServiceForm = () => {
  const [organization_id] = useOrganizationId()
  return (
    <SimpleForm defaultValues={{ organization_id }}>
      <ReferenceInput
        key="service_template"
        reference="service_templates"
        source="service_template_id"
        filter={{
          organization_id,
          "deleted_at@is": "NULL",
        }}
      >
        <AutocompleteInput
          fullWidth
          source="name"
          optionText="name"
          filterToQuery={(query) => ({
            "name@ilike": query,
          })}
        />
      </ReferenceInput>
      <ReferenceInput
        key="tool"
        reference="tools"
        source="tool_id"
        filter={{
          organization_id,
          "deleted_at@is": null,
          "tool_photo@not.is": null,
          // "inactive_status@not.in": `(${InactiveStatus.Consumed},${InactiveStatus.Lost},${InactiveStatus.Stolen},${InactiveStatus.Destroyed})`,
        }}
      >
        <AutocompleteInput
          fullWidth
          source="display_name"
          optionText="display_name"
          filterToQuery={(query) => ({
            "display_name@ilike": query,
          })}
        />
      </ReferenceInput>
      <CrewMemberInput
        source="crew_member_id"
        organization_id={organization_id}
        showAutoToolroom={false}
      />
      <DueUtilizationInput />
    </SimpleForm>
  )
}
