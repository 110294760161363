import { useDisclosure } from "@dwarvesf/react-hooks"
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined"
import { Dialog, DialogTitle, InputAdornment } from "@mui/material"
import { useEffect } from "react"
import {
  AutocompleteInput,
  Button,
  DateInput,
  NumberInput,
  ReferenceInput,
  ResourceContextProvider,
  SimpleForm,
  useCreate,
  useGetOne,
  useListContext,
  useResourceContext,
  useUnselectAll,
} from "react-admin"
import { useForm, useWatch } from "react-hook-form"
import { useOrganizationId } from "../../hooks/useOrganizationId"
import { CrewMemberInput } from "./CrewMemberInput"

export function CreateToolServiceButton(props) {
  const [organization_id] = useOrganizationId()
  const resource = useResourceContext(props)
  const { selectedIds } = useListContext(props)
  const unselectAll = useUnselectAll(resource)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [create] = useCreate()
  const handleCreate = async (record) => {
    await Promise.all(
      selectedIds.map((tool_id) => {
        const data = { ...record, tool_id, organization_id }
        return create("tool_services", { data })
      })
    )
    onClose()
    unselectAll()
  }

  return (
    <>
      <Button
        label="Schedule Service"
        onClick={onOpen}
        startIcon={<ScheduleOutlinedIcon />}
      />
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>Schedule Service </DialogTitle>
        <ResourceContextProvider value="tool_services">
          <SimpleForm onSubmit={handleCreate}>
            <ReferenceInput
              reference="service_templates"
              source="service_template_id"
              filter={{
                organization_id,
                "deleted_at@is": "NULL",
              }}
            >
              <AutocompleteInput
                fullWidth
                source="name"
                optionText="name"
                filterToQuery={(query) => ({
                  "name@ilike": query,
                })}
              />
            </ReferenceInput>
            <CrewMemberInput
              source="crew_member_id"
              organization_id={organization_id}
            />
            <DueUtilizationInput />
          </SimpleForm>
        </ResourceContextProvider>
      </Dialog>
    </>
  )
}

export function DueUtilizationInput() {
  const service_template_id = useWatch({ name: "service_template_id" })
  const { data } = useGetOne("service_templates", { id: service_template_id })
  const { is_utilization_based = false, utilization_unit } = data || {}
  const { resetField } = useForm()
  useEffect(() => {
    console.log("is_utilization_based", is_utilization_based)
    if (!is_utilization_based) {
      resetField("due_utilization", undefined)
    } else {
      resetField("due_date", undefined)
    }
  }, [is_utilization_based, resetField])

  if (is_utilization_based)
    return (
      <NumberInput
        source="due_utilization"
        label="Due Utilization"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{utilization_unit}</InputAdornment>
          ),
        }}
      />
    )
  return <DateInput source="due_date" />
}
