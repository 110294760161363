import { BulkUpdateFormButton } from "@react-admin/ra-form-layout"
import { Datagrid, List, SimpleForm } from "@react-admin/ra-rbac"
import {
  AutocompleteInput,
  BulkDeleteButton,
  DateField,
  DateInput,
  FunctionField,
  ReferenceField,
  ReferenceInput,
  TextField,
  TextInput,
} from "react-admin"
import { useOrganizationId } from "../../../hooks/useOrganizationId"
import { IntervalField } from "../../Components/IntervalField"
import { CrewMemberInput } from "../../Tool/CrewMemberInput"
import { ProjectInput } from "../../Tool/ProjectInput"
import { toolServicesExporter } from "./toolServicesExporter"

export const ToolServiceList = () => {
  const [organization_id] = useOrganizationId()
  return (
    <List
      exporter={toolServicesExporter}
      filters={[
        <TextInput
          key="tools.search_terms@fts"
          label="Search"
          // We are doing this rather than wfts so we can get partial matches on the final word
          source="tools.search_terms@fts"
          alwaysOn
        />,
        <ReferenceInput
          reference="service_templates"
          source="service_template_id"
          filter={{ organization_id }}
        >
          <AutocompleteInput
            optionText="name"
            filterToQuery={(searchText) => ({ "name@ilike": searchText })}
          />
        </ReferenceInput>,
        <CrewMemberInput
          source="crew_member_id"
          organization_id={organization_id}
        />,
        <ProjectInput
          source="tools.project_id"
          organization_id={organization_id}
        />,
        <DateInput source="due_date@gte" />,
        <DateInput source="due_date@lte" />,
      ]}
      filter={{
        "select@":
          "*,tools!inner(search_terms,full_display_name,asset_tag,projects!t_tools_project_id_fkey(project_name),project_id),crew_members(name)",
        organization_id,
        "completed_at@is": null,
        "deleted_at@is": null,
        "tools.organization_id": organization_id,
        "crew_members.organization_id": organization_id,
      }}
    >
      <ToolServiceDatagrid
        bulkActionButtons={
          <>
            <BulkUpdateFormButton>
              <SimpleForm>
                <CrewMemberInput
                  source="crew_member_id"
                  organization_id={organization_id}
                />
                <DateInput source="due_date" />
              </SimpleForm>
            </BulkUpdateFormButton>
            <BulkDeleteButton />
          </>
        }
      />
    </List>
  )
}

function ToolServiceDatagrid(props) {
  return (
    <Datagrid {...props} rowClick="show">
      <TextField source="tools.asset_tag" />
      <TextField source="tools.full_display_name" />
      <ReferenceField
        source="service_template_id"
        reference="service_templates"
      />
      <TextField source="crew_members.name" />
      <TextField source="tools.projects.project_name" />
      <DateField source="due_date" />
      <TextField source="due_utilization" />
      <ReferenceField
        source="service_template_id"
        reference="service_templates"
        label="Recurring" // TODO: i18n
      >
        <FunctionField
          render={({ is_recurring }) => (is_recurring ? "Every " : "After")}
        />
        <IntervalField source="service_interval" />
        <TextField source="service_utilization_interval" />{" "}
        <TextField source="service_utilization_unit" />
      </ReferenceField>
    </Datagrid>
  )
}
