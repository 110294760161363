import DownloadIcon from "@mui/icons-material/Download"
import { useMemo } from "react"
import {
  AutocompleteInput,
  Button,
  Datagrid,
  DateField,
  DateInput,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  TextField,
  useCreate,
  useListContext,
  useNotify,
  useUnselectAll,
} from "react-admin"
import { useOrganizationId } from "../../hooks/useOrganizationId"
import { PercentField } from "../Components/PercentField"
const AccountingReportFilters = (organization_id) => [
  <ReferenceInput
    key="project"
    reference="projects"
    source="project_id"
    sort={{ field: "updated_at", order: "DESC" }}
    filter={{
      organization_id,
      "deleted_at@is": null,
    }}
    perPage={100}
    alwaysOn
  >
    <AutocompleteInput
      source="project_name"
      optionText="project_name"
      filterToQuery={(query) => ({ "project_name@ilike": query })}
    />
  </ReferenceInput>,
  <ReferenceInput
    key="tool"
    reference="tools"
    source="tool_id"
    sort={{ field: "updated_at", order: "DESC" }}
    filter={{
      organization_id,
      "deleted_at@is": null,
    }}
    perPage={100}
    alwaysOn
  >
    <AutocompleteInput
      source="display_name"
      optionText="display_name"
      filterToQuery={(query) => ({ "display_name@ilike": query })}
    />
  </ReferenceInput>,
  <DateInput source="period_end@gte" />,
  <DateInput source="period_end@lt" />,
]

const HH2AccountingReportExportButton = () => {
  const { resource, data, selectedIds } = useListContext()
  const [organization_id] = useOrganizationId()
  const notify = useNotify()
  const unselectAll = useUnselectAll(resource)

  const selectedRecords = data.filter((record) =>
    selectedIds.includes(record.id)
  )
  const [create, { isLoading }] = useCreate("projects")
  return (
    <Button
      label="ra.action.hh2.accountingReport.export.selected"
      disabled={isLoading}
      onClick={async () => {
        await Promise.all(
          selectedRecords.map((record) =>
            create(
              "hh2/job_costing",
              {
                meta: {
                  organization_id,
                },
                data: {
                  // Request Body
                  // {
                  // "AccountingDate": "String content",
                  // "Amount": 12678967.543233,
                  // "CategoryId": "1627aea5-8e0a-4371-9022-9b504344e724",
                  // "CostCodeId": "1627aea5-8e0a-4371-9022-9b504344e724",
                  // "CreditAccountId": "1627aea5-8e0a-4371-9022-9b504344e724",
                  // "DebitAccountId": "1627aea5-8e0a-4371-9022-9b504344e724",
                  // "Description": "String content",
                  // "JobId": "1627aea5-8e0a-4371-9022-9b504344e724",
                  // "Reference1": "String content",
                  // "Reference2": "String content",
                  // "StandardCategoryId": "1627aea5-8e0a-4371-9022-9b504344e724",
                  // "StandardItemId": "1627aea5-8e0a-4371-9022-9b504344e724",
                  // "Tag": "String content",
                  // "TransactionDate": "String content",
                  // "TransactionType": 0,
                  // "UnitCost": 12678967.543233,
                  // "Units": 12678967.543233,
                  // "PartnerReferenceId": "String content"
                  // }
                  // Response Body
                  // "1627aea5-8e0a-4371-9022-9b504344e724"
                  // Notes
                  // Response body contains the Guid of the record that was created.
                  // TransactionType - Supported Values
                  // 1 = Accounts Payable
                  // 2 = Job cost
                  // 3 = Payroll
                  // 4 = Equipment
                  // 5 = Purchasing and Inventory
                  // 6 = General Ledger Only
                  accountingDate: new Date().getUTCDate(),
                  amount: record.capped_rent,
                  categoryId: "TODO",
                  // TODO: Remove hardcoding by using  imported cost codes
                  costCodeId: "c386adf4-b837-489c-b1f2-b21e01545174",
                  creditAccountId: "TODO",
                  debitAccountId: "TODO",

                  project_name: `${record.code} ${record.name}`,
                  hh2_id: record.id,
                },
              },
              {
                onSuccess: () => {},
                onError: (error: string) => {
                  console.error(error)
                },
              }
            )
          )
        )
        notify(`ra.action.hh2.accountingReport.export.notification`, {
          type: "success",
          messageArgs: { smart_count: selectedRecords.length },
        })
        unselectAll()
      }}
    >
      <DownloadIcon />
    </Button>
  )
}

export function AccountingReportList() {
  const [organization_id] = useOrganizationId()
  const filters = useMemo(
    () => AccountingReportFilters(organization_id),
    [organization_id]
  )
  return (
    <List
      filter={{
        organization_id,
      }}
      filters={filters}
    >
      <Datagrid
        rowClick="edit"
        bulkActionButtons={<HH2AccountingReportExportButton />}
        // isRowSelectable={(record) => !!record.hh2_id}
      >
        <ReferenceField source="project_id" reference="projects" />
        <TextField source="category" />
        <ReferenceField source="tool_id" reference="tools" />
        <DateField source="period_start" />
        <DateField source="period_end" />
        <TextField source="rental_rent" />
        <TextField source="rental_cost" />
        <TextField source="period_rent" />
        <TextField source="bill_type" />
        <TextField source="value" />
        <NumberField source="quantity" />
        <TextField source="capped_rent" />
        <TextField source="remaining_cap" />
        <PercentField source="cap_percentage" />
        <TextField source="cost_code" />
      </Datagrid>
    </List>
  )
}
