import { Button } from "react-admin"
import { procoreAuthUrl } from "../Providers/procoreProvider"

export function ProcoreImportButton({
  resource,
  projectId,
  companyId,
}: {
  resource: string
  projectId?: string
  companyId?: string
}) {
  const url = projectId ? `projects/${projectId}/${resource}` : resource
  const location = `${
    document.location.origin
  }/procore/companies?resource=${url}${
    companyId ? `&companyId=${companyId}` : ""
  }`
  return (
    <Button
      onClick={() => {
        window.open(procoreAuthUrl(location), "_self")
      }}
      label={`ra.action.procore.${resource}.import.button`}
      startIcon={
        <img width="12" height="12" src="/procore-bw.png" alt="PROCORE" />
      }
    />
  )
}
