import UploadIcon from "@mui/icons-material/Upload"
import {
  Button,
  Datagrid,
  List,
  SearchInput,
  TextField,
  useCreate,
  useListContext,
  useNotify,
  useUnselectAll,
} from "react-admin"
import { useOrganizationId } from "../../hooks/useOrganizationId"

const HH2JobImportButton = () => {
  const { resource, data, selectedIds } = useListContext()
  const [organization_id] = useOrganizationId()
  const notify = useNotify()
  const unselectAll = useUnselectAll(resource)

  const selectedRecords = data.filter((record) =>
    selectedIds.includes(record.id)
  )
  const [create, { isLoading }] = useCreate("projects")
  return (
    <Button
      label="ra.action.hh2.jobs.import.selected"
      disabled={isLoading}
      onClick={async () => {
        await Promise.all(
          selectedRecords.map((record) =>
            create(
              "projects",
              {
                data: {
                  organization_id,
                  project_name: `${record.code} ${record.name}`,
                  hh2_id: record.id,
                },
              },
              {
                onError: (error: string) => {
                  console.error(error)
                },
              }
            )
          )
        )
        notify(`ra.action.hh2.jobs.import.notification`, {
          type: "success",
          messageArgs: { smart_count: selectedRecords.length },
        })
        unselectAll()
      }}
    >
      <UploadIcon />
    </Button>
  )
}
export function HH2JobList() {
  const [organization_id] = useOrganizationId()

  return (
    <List
      filter={{ organization_id }}
      filters={[<SearchInput source="q" alwaysOn />]}
    >
      <Datagrid bulkActionButtons={<HH2JobImportButton />}>
        <TextField source="code" />
        <TextField source="name" />
        {/*
    <NumberField source="version" />
    <TextField source="address1" />
    <TextField source="address2" />
    <NumberField source="billingLevel" />
    <NumberField source="billingMethod" />
    <TextField source="city" />
    <DateField source="createdOnUtc" />
    <ReferenceField source="hasExternalId" reference="hasExternals" />
    <BooleanField source="isActive" />
    <BooleanField source="isArchived" />
    <NumberField source="jobToDateCostAmount" />
    <NumberField source="jobToDateCostPaymentAmount" />
    <NumberField source="jobToDateRevenuePaymentAmount" />
    <NumberField source="jobToDateRevenueRetainageHeldAmount" />
    <NumberField source="jobToDateWorkBilledAmount" />
    <NumberField source="lastMonthCostAmount" />
    <NumberField source="lastMonthCostPaymentAmount" />
    <NumberField source="lastMonthRevenuePaymentAmount" />
    <NumberField source="lastMonthReveueRetainageHeldAmount" />
    <NumberField source="lastMonthWorkBilledAmount" />
    <DateField source="misc1Amount" />
    <DateField source="misc2Amount" />
    <DateField source="misc3Amount" />
    <NumberField source="monthToDateCostAmount" />
    <NumberField source="monthToDateCostPaymentAmount" />
    <NumberField source="monthToDateRevenuePaymentAmount" />
    <NumberField source="monthToDateRevenueRetainageHeldAmount" />
    <NumberField source="monthToDateWorkBilledAmount" />
    <TextField source="postalCode" />
    <BooleanField source="shouldUseProjectManagement" />
    <NumberField source="status" />
    <TextField source="country" />
    <TextField source="state" />
    <TextField source="parentCode" />
    <ReferenceField source="parentId" reference="parents" />
    <TextField source="parentName" /> */}
      </Datagrid>
    </List>
  )
}
