import * as Sentry from "@sentry/react"
import LogRocket from "logrocket"
import setupLogRocketReact from "logrocket-react"
import { supabase } from "./admin/Providers/supabase"

if (window.location.hostname === "localhost") {
  console.log("Not logging errors in development")
} else {
  Sentry.init({
    dsn: "https://ec4bcb01ed5640b9ab07f25eb451e6a6@o1023426.ingest.sentry.io/4505095156072448",
  })

  LogRocket.init("4fzger/tooltribe-web-admin")
  setupLogRocketReact(LogRocket)

  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra("sessionURL", sessionURL)
    })
  })

  supabase.auth.onAuthStateChange((event, session) => {
    if (event === "SIGNED_IN" && session?.user) {
      LogRocket.identify(session?.user?.id, {
        name: session?.user?.user_metadata?.full_name,
        email: session?.user?.email || "",
        phone: session?.user?.phone || "",
      })
    }
  })
}
