import UploadIcon from "@mui/icons-material/Upload"
import { isPossiblePhoneNumber, parsePhoneNumber } from "libphonenumber-js"
import {
  Button,
  Datagrid,
  FunctionField,
  List,
  SearchInput,
  TextField,
  useCreate,
  useListContext,
  useNotify,
  useUnselectAll,
} from "react-admin"
import { useOrganizationId } from "../../hooks/useOrganizationId"

const HH2EmployeeImportButton = () => {
  const { resource, data, selectedIds } = useListContext()
  const [organization_id] = useOrganizationId()
  const notify = useNotify()
  const unselectAll = useUnselectAll(resource)

  const selectedRecords = data.filter((record) =>
    selectedIds.includes(record.id)
  )
  const [create, { isLoading }] = useCreate("projects")
  return (
    <Button
      label="ra.action.hh2.employees.import.selected"
      disabled={isLoading}
      onClick={async () => {
        await Promise.all(
          selectedRecords.map((record) => {
            const [lastName, firstName] = record.fullName.split("; ")
            const name = `${firstName} ${lastName}`
            const phone_number =
              record.mainPhoneNumber != null &&
              isPossiblePhoneNumber(record.mainPhoneNumber, "US")
                ? parsePhoneNumber(record.mainPhoneNumber, "US").format("E.164")
                : null
            return create(
              "crew_members",
              {
                data: {
                  organization_id,
                  name,
                  phone_number,
                  email: record.emailAddress,
                  employee_id: record.code,
                  // hh2_id: record.id,
                },
              },
              {
                onSuccess: () => {},
                onError: (error: string) => {
                  console.error(error)
                },
              }
            )
          })
        )
        notify(`ra.action.hh2.employees.import.notification`, {
          type: "success",
          messageArgs: { smart_count: selectedRecords.length },
        })
        unselectAll()
      }}
    >
      <UploadIcon />
    </Button>
  )
}
export function HH2EmployeeList() {
  const [organization_id] = useOrganizationId()

  return (
    <List
      filter={{ organization_id }}
      pagination={false}
      filters={[<SearchInput source="q" alwaysOn />]}
    >
      <Datagrid bulkActionButtons={<HH2EmployeeImportButton />}>
        <TextField source="code" />
        <FunctionField
          source="fullName"
          render={(record, source) => {
            // they have first and last but no telling if that's correct
            const [lastName, firstName] = record[source].split("; ")
            return `${firstName} ${lastName}`
          }}
        />
        <TextField source="mainPhoneNumber" />
        <TextField source="emailAddress" />
      </Datagrid>
    </List>
  )
}
