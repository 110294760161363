import { Show } from "@react-admin/ra-rbac"
import {
  DateField,
  FileField,
  FunctionField,
  NumberField,
  ReferenceField,
  SimpleShowLayout,
  TextField,
} from "react-admin"

export const ServiceRecordShow = () => (
  <Show>
    <SimpleShowLayout>
      <ReferenceField source="tool_id" reference="tools" />
      <ReferenceField source="tool_service_id" reference="tool_services">
        <ReferenceField
          source="service_template_id"
          reference="service_templates"
        />
      </ReferenceField>
      <TextField source="notes" />
      <TextField source="cost" />
      <FileField
        label="resources.service_records.fields.attachment"
        source="attachment.src"
        target="_blank"
        title="attachment.title"
      />
      <FunctionField
        source="checklist"
        render={(record, source) => {
          const checklist = record[source]
          return checklist
            ? checklist
                .map((item) => item.step + (item.complete ? " ✓" : " ✗"))
                ?.join(", ")
            : ""
        }}
      />{" "}
      <ReferenceField source="crew_member_id" reference="crew_members" />
      <DateField source="completed_at" />
      <NumberField source="competed_utilization" />
    </SimpleShowLayout>
  </Show>
)
